<template>
<div>
  <el-card class="box-card">
<!--    <div slot="header" class="clearfix">-->
<!--      <div style="font-size: 32px;text-align: center">成语大全</div>-->
<!--&lt;!&ndash;      <el-button style="float: right; padding: 3px 0" type="text">帮助</el-button>&ndash;&gt;-->
<!--    </div>-->
    <el-row gutter="10">
      <el-col :span="18">
        <div style="width: 100%;height: calc(100vh - 208px);background-color: #ddd;border-radius: 10px;overflow-y: auto;">
          <div style="width: 100%; padding: 20px;">
            <div style="padding-bottom: 20px;font-size: 32px;font-weight: 600;color: #7fa0df">{{item.name}}</div>
            <div>拼音：<span>{{item.pinyin}}</span> </div>
            <div class="titleBox">基本释义</div>
            <div class="subBox">{{item.jbsy}}</div>
            <div class="titleBox">详细释义</div>
            <div class="subBox">{{item.xxsy}}</div>
            <div class="titleBox">成语出处</div>
            <div class="subBox">{{item.chuchu}}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div style="width: 100%;height: calc(100vh - 208px);border: 2px solid #ddd;border-radius: 10px;">
          <div style="width: 100%;height:100px;margin: 20px;display: flex;flex-direction: column;align-items: center;justify-content: space-around">
            <el-button type="primary" style="width: 60%" @click="randomClick">随机选择一条</el-button>
            <el-input placeholder="请输入关键字" prefix-icon="el-icon-search" maxlength="8" clearable
                      @input="handleInput" v-model="input" style="width: 60%">
            </el-input>
          </div>
          <div style="margin: 40px;display: flex;flex-direction: column;height: 400px;overflow: auto">
            <div class="itemBox" v-for="(item, index) in listTemp" @click="itemClick(item.id)">
              {{item.name}}
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-card>
</div>
</template>
<script>
    import axios from "axios";
    export default {
        name: 'idiom',
        data() {
          return {
            item:{
              name: '成语',
              pinyin: '拼音',
              jbsy: '基本释义',
              xxsy: '详细释义',
              chuchu: '出处',
              liju: '例句',
              jyc: '近义词',
              fyc: '反义词',
            },
            list:[],      // 所有成语队列
            listName: [], // 成语名队列
            listTemp: [], // 搜索到的供选择
            input: '',
          }
        },
        mounted() {
            const loading = this.$loading({
              lock: true,
              text: '正在加载成语大全数据',
              spinner: 'el-icon-loading',
              background: 'rgba(121, 121, 121, .8)',
              target: document.querySelector('.box-card'),
              customClass: 'create-isLoading',  // *这里设置他的class名称,这里最重要
            });
            axios.get('/idiom_1.0.0.json')
                .then(res => {
                  // localStorage.setItem("idiom", JSON.stringify(res.data)) 5M以上是无法保存本地的！
                  this.list = res.data;
                  this.list.map((item,index)=>{
                    this.listName.push({id:index,name:item.name});
                  })
                  this.listTemp = this.listName;
                  this.itemClick(0);
                  //取消loading状态:
                  loading.close()
                })
                .catch(error => {
                  console.log(error)
                  //取消loading状态:
                  loading.close()
                })


        },
        methods: {
          randomClick(){
            let max = this.listName.length
            let index = Math.floor(Math.random() * (max + 1))
            this.itemClick(index)
          },
          itemClick(index){
            this.item.name = this.list[index].name
            this.item.pinyin = this.list[index].pinyin
            this.item.jbsy = this.list[index].jbsy !== 'null' ? JSON.parse(this.list[index].jbsy).join() : ''
            this.item.xxsy = this.list[index].xxsy !== 'null' ? JSON.parse(this.list[index].xxsy).join() : ''
            this.item.chuchu = this.list[index].chuchu
            this.item.liju = this.list[index].liju
            this.item.jyc = this.list[index].jyc !== 'null' ? JSON.parse(this.list[index].jyc).join() : ''
            this.item.fyc = this.list[index].fyc !== 'null' ? JSON.parse(this.list[index].fyc).join() : ''
          },

          handleInput(){
            // 1 获取输入框的值
            // 2 检测合法性
            if(!this.input.trim()){
              this.listTemp = this.list
              // 值不合法
              return;
            }
            // 3 准备发送请求获取数据
            clearTimeout(this.TimeId);
            this.TimeId=setTimeout(() => {
              this.qsearch(this.input);
            }, 1); //延迟1ms？
          },
          // 发送请求获取搜索建议 数据
          async qsearch(query){
            var tempFiles=[]
            this.listName.forEach((item,index,arr)=> {
              if(item.name.indexOf(query)!=-1){
                tempFiles.push(item)
              }
            })
            this.listTemp = tempFiles
          },
          // 点击 取消按钮
          handleCancel(){
            this.setData({
              inpValue:"",
              isFocus:false,
              files:[]
            })
          },
        }
    }
</script>
<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.titleBox{
  padding-top: 20px;
}
.subBox{
  height: 100px;
  padding: 10px;
  background-color: grey;
  overflow-y: auto;
}
.itemBox{
  cursor: pointer;
  margin: 2px;
  padding:8px;
  background-color: orange;
}
.create-isLoading {

.el-loading-spinner {
  top: 50%;
  left: 50%;
  margin-left: -55px;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 4px;
  width: auto;
  text-align: center;
  position: absolute;

i {
  color: #eee;
}

.el-loading-text {
  font-size: 32px;
  color: #eee;
}

}
}
</style>